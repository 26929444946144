<template>
    <div class="goods-filter">
        <div class="filter-dropdown">
            <div
                v-for="(key, index) in specKeys"
                :key="key"
                class="rp-goods-filter-btn"
                type="button"
                :aria-expanded="visibles.specs[index]"
            >
            <a-dropdown
                :trigger="['click']"
                placement="bottomCenter"
                v-model:visible="visibles.specs[index]"
            >
                <template #overlay>
                    <a-menu>
                        <a-menu-item>
                            <a-checkbox-group
                                :options="specs[key].map(spec => ({ label: spec, value: spec }))"
                                v-model:value="conditions.attr[key]"
                            />
                        </a-menu-item>
                        <a-menu-item class="applyBtn" key="btn">
                            <a-button type="primary" @click="() => onApply('specs', index)">{{t('goodsFilter.p1')}}</a-button>
                        </a-menu-item>
                    </a-menu>
                </template>
                <div>
                    <div class="content">
                        <span class="label">{{ key }}</span>
                        <span class="value" v-if="isSelected(key)">
                            {{`: ${conditions.attr[key].length}`}}
                        </span>
                    </div>
                    <DownOutlined v-if="!isSelected(key)"/>
                </div>
            </a-dropdown>
            <div
                class="clear-btn"
                v-if="conditions.attr[key]?.length"
                @click="() => onClear(key)"
            >
                <CloseOutlined style="color: #E65050;" />
            </div>
        </div>
        <a-dropdown :trigger="['click']" v-model:visible="visibles.price">
            <template #overlay>
                <a-menu class="priceDropdown" @click="priceClick">
                    <a-menu-item key="price">
                    <a-input-number
                        :min="0"
                        :formatter="formatter"
                        :parser="parser"
                        v-model:value="conditions.price[0]"
                    />-
                    <a-input-number
                        :min="conditions.price[0]+1"
                        :formatter="formatter"
                        :parser="parser"
                        v-model:value="conditions.price[1]"
                    />
                    </a-menu-item>
                    <a-menu-item class="applyBtn" key="btn">
                        <a-button type="primary" @click="() => onApply('price')">{{t('goodsFilter.p1')}}</a-button>
                    </a-menu-item>
                </a-menu>
            </template>
            <a-button class="price-btn" type="default" :aria-expanded="visibles.price">{{t('goodsFilter.p2')}}<DownOutlined /> </a-button>
        </a-dropdown>
    </div>
        <div class="others">
            <a-button type="default" :disabled="disabled" @click="() => onClear()">{{t('goodsFilter.p3')}}</a-button>
            <a-select :value="conditions.sort" @change="onSortRuleChange">
                <Option :value="1">{{t('goodsFilter.p4')}}</Option>
                <Option :value="0">{{t('goodsFilter.p5')}}</Option>
                <Option :value="2">{{t('goodsFilter.p6')}}</Option>
                <Option :value="3">{{t('goodsFilter.p7')}}</Option>
            </a-select>
        </div>
    </div>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import importComponents from '@/utils/import-components';
import { DownOutlined, CloseOutlined } from '@ant-design/icons-vue';
import {
  Button,
  Checkbox,
  Dropdown,
  Menu,
  InputNumber,
  Select,
} from 'ant-design-vue';
import {
  defineComponent, reactive, computed, ref,
} from 'vue';
import type { MenuOnClick } from '@/typings/ant-design-vue';
import { cloneDeep } from 'lodash';
import { useRoute } from 'vue-router';

type Specs = Record<string, string[]>;

export type Conditions = {
  sort: 0 | 1 | 2 | 3;
  attr: Specs;
  price: [number, number];
}

export interface GoodsFilterProps {
  change: (value: Conditions) => void;
  sepcs: Specs;
}

type Visibles = {
  specs: boolean[];
  price: boolean;
}

export default defineComponent({
  name: 'GoodsFilter',
  components: {
    ...importComponents(
      Button,
      Checkbox,
      Checkbox.Group,
      Menu,
      Menu.Item,
      DownOutlined,
      CloseOutlined,
      Dropdown,
      InputNumber,
      Select,
    ),
    Option: Select.Option,
  },
  props: {
    specs: {
      type: Object as () => Specs,
      required: true,
    },
  },
  setup(props, ctx) {
    const { t } = useI18n();
    /**
         * 可选择的规格 Key
         */
    const specKeys = computed(() => Object.keys(props.specs));

    /**
         * 选择的规格
         */
    const route = useRoute();
    const { sort } = route.query; // 首页图片点击url会带上sort指定选择的规格
    const conditions = reactive<Conditions>({
      sort: (sort ? +sort : 0) as 0 | 1 | 2 | 3,
      attr: {},
      price: [0, 0],
    });

    const disabled = ref(true);

    const emit = () => {
      const payload = cloneDeep(conditions);
      Object.entries(payload.attr).forEach(([key, value], index, array) => {
        if (value.length === 0) {
          Reflect.deleteProperty(payload.attr, key);
        }
        if (index === array.length - 1 && Object.keys(payload.attr).length === 0) {
          Reflect.set(payload, 'attr', {});
        }
      });
      ctx.emit('change', payload);
    };

    /**
         * 下拉框的显示隐藏
         */
    const visibles = reactive<Visibles>({
      specs: [],
      price: false,
    });

    const priceClick: MenuOnClick = ({ key }) => {
      if (key === 'btn') {
        visibles.price = false;
      }
    };

    /**
         * 清除规格
         */
    const onClear = (key: string) => {
      if (key === undefined) {
        conditions.attr = specKeys.value.reduce((result, specKey) => {
          Reflect.set(result, specKey, []);
          return result;
        }, {} as Specs);
        conditions.price = [0, 0];
        disabled.value = true;
      } else {
        conditions.attr[key] = [];
      }
      emit();
    };

    /**
         * 点击 APPLY 按钮后触发
         */
    const onApply = (type: 'specs' | 'price', index: number) => {
      if (type === 'specs') {
        visibles[type][index] = false;
      } else {
        visibles[type] = false;
      }
      disabled.value = false;
      emit();
    };

    /**
         * 排序规则被修改
         */
    const onSortRuleChange = (value: Conditions['sort']) => {
      conditions.sort = value;
      emit();
    };

    /**
         * 用在 template 中，判断规则是否有选择
         */
    const isSelected = (key: string) => {
      const attr = conditions.attr[key];
      if (attr === undefined || attr.length === 0) {
        return false;
      }
      return true;
    };

    /**
         * 下拉按钮上的 aria-expanded="false" 和 "true" 切换
         */
    // const ariaAttr = ref(false);
    // const changeAriaAttr = () => {
    //     ariaAttr.value = !ariaAttr.value;
    // };

    const formatter = (value: number) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    const parser = (value: string) => value.replace(/\$\s?|(,*)/g, '');

    return {
      isSelected,
      onClear,
      onApply,
      formatter,
      parser,
      visibles,
      priceClick,
      onSortRuleChange,
      conditions,
      specKeys,
      disabled,
      t,
      // ariaAttr,
      // changeAriaAttr,
    };
  },
});
</script>
<style lang="scss" scoped>
@import "@/assets/styles/mixin.scss";
@import "@/assets/styles/variables.scss";

.goods-filter {
    display: flex;
    .filter-dropdown {
        display: flex;
        justify-content: space-between;
        [ant-click-animating-without-extra-node="true"]::after,
        .ant-click-animating-node {
            box-shadow: $box-shadow !important;
        }
    }
    .others {
        flex: 1;
        margin-left: 61px;
        display: flex;
        justify-content: space-between;
        :deep(.ant-select) {
            .ant-select-selector {
                @include select-border();
            }
        }
        [ant-click-animating-without-extra-node="true"]::after,
        .ant-click-animating-node {
        box-shadow: $box-shadow !important;
        }
    }
}
.ant-btn {
    font-weight: 500;
    color: $font-color;
    border-radius: 4px;
    &:hover,
    &:focus,
    &:active {
        border-color: #d9d9d9;
    }
}
:deep(.ant-checkbox-wrapper) {
    color: $font-color;
    &:hover {
        .ant-checkbox {
        .ant-checkbox-inner {
            border-color: $theme-color;
        }
        }
    }
    .ant-checkbox-checked {
        .ant-checkbox-inner {
            border-color: $theme-color;
            background-color: $theme-color;
        }
        &:hover {
            border-color: $theme-color;
        }
    }
}
.ant-dropdown-menu {
    border-radius: 4px;
    .ant-dropdown-menu-item {
        .ant-checkbox-group {
            display: flex;
            flex-direction: column;
            line-height: 2.2;
            :deep(.ant-checkbox-checked::after){
              border:1px solid $theme-color !important;
            }
            :deep(.ant-checkbox-input:focus + .ant-checkbox-inner) {
              border-color: $theme-color;
            }
            &-active {
              background: #ffffff;
            }
        }
        .ant-input-number:focus {
            border-color: $theme-color;
        }
    }
}
.applyBtn {
    text-align: center;
    .ant-btn {
        margin: unset !important;
    }
}
.ant-dropdown ul li:hover,
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
    background-color: unset;
}
.ant-btn-primary {
    background-color: $theme-color;
    border-color: $theme-color;
    color: #ffffff;
    &:hover,
    &:focus {
        background-color: $theme-color;
        border-color: $theme-color;
    }
}
.priceDropdown {
    .ant-input-number:hover {
        border-color: $theme-color;
    }
    .ant-input-number:focus {
        box-shadow: $box-shadow;
    }
    .ant-input-number-focused {
        border-color: $theme-color;
        box-shadow: $box-shadow;
    }
    .applyBtn {
        text-align: center;
        &:hover {
            background-color: unset;
        }
        .ant-btn-primary {
            background-color: $theme-color;
            border-color: $theme-color;
            &:hover,
            &:focus {
                border-color: $theme-color;
            }
        }
    }
}
.ant-select {
    width: 180px;
    .ant-select-selector {
        border: 2px solid #e1e1e1;
    }
}
.rp-goods-filter-btn {
    border: 1px solid #d9d9d9;
    font-weight: 500;
    color: #1c1c1c;
    border-radius: 4px;
    background: #ffffff;
    outline: 0;
    position: relative;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    cursor: pointer;
    user-select: none;
    padding: 0 15px;
    display: inline-block;
    box-sizing: border-box;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    display: flex;
    align-items: center;
    &:first-of-type ~ .rp-goods-filter-btn {
        margin-left: 6px;
    }
    .ant-dropdown-trigger {
        display: flex;
        align-items: center;
        height: 32px;
    }
    .content {
        margin-right: 8px;
        .label {
        text-transform: capitalize;
        }
    }
    .clear-btn {
        background: #f0f0f0;
        height: 16px;
        width: 16px;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        z-index: 9;
    }
}
.price-btn {
    margin-left: 32px;
}
</style>
