
import importComponents from '@/utils/import-components';
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'SearchNoResult',
  components: {
    ...importComponents(
    ),
  },
  setup() {
    const route = useRoute();
    const { keyword } = route.params;
    const { t } = useI18n();
    return {
      keyword,
      t,
    };
  },
});
