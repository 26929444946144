<!--
 * @Author: your name
 * @Date: 2021-01-07 09:53:01
 * @LastEditTime: 2021-02-01 17:31:48
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /web-pc/src/views/SearchNoResult.vue
-->
<template>
<!-- 搜索无结果 -->
<div class="no-match-result">
    <div class="content-wrapper">
        <div class="content">
            <h2 v-if="keyword">{{t('searchNoResult.p1')}}{{keyword}}</h2>
            <div class="null-content">
                <img src="@/assets/icons/searchicon.png" alt="search button">
                <span>{{t('searchNoResult.p2')}}</span>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import importComponents from '@/utils/import-components';
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'SearchNoResult',
  components: {
    ...importComponents(
    ),
  },
  setup() {
    const route = useRoute();
    const { keyword } = route.params;
    const { t } = useI18n();
    return {
      keyword,
      t,
    };
  },
});
</script>

<style lang="scss" scoped>
    @import "@/assets/styles/mixin.scss";
    @import '@/assets/styles/variables.scss';
    .no-match-result{
        padding: 0 100px;
        .content-wrapper{
            @include flex-center();
            padding-top: 25px;
            .content{
                h2{
                font-size: 25px;
                font-weight: 500;
                color: #000000;
                line-height: 30px;
                }
                .null-content{
                    padding: 247px 0 313px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    img{
                        width: 69px;
                        height: 69px;
                        margin-bottom: 30px;
                    }
                    span{
                    font-size: 20px;
                    font-weight: 500;
                    color: $font-color-weak;
                    line-height: 24px;
                    }
                }
            }
        }
    }
</style>
