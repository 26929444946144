<template>
    <div class="r-goods-card" @click="() => router.push(`/goods/${data.goodsId}?s=${data.skuId}`)">
        <div class="goods-img">
            <PictureBox :src="`${data.img}?x-oss-process=image/resize,w_170,h_150`" alt="goodsName"/>
        </div>
        <cart-btn v-if="data.realityStock > 0"
            :goodsId="data.goodsId"
            :skuId="data.skuId"
            :realityStock="data.realityStock"
            />
        <div class="text">
            <p class="goods-name">
                {{ data.name }}
                {{ data.goodsAttr ? Object.values(data.goodsAttr).map((item) => item).join(' ') : '' }}
            </p>
            <span class="current-price">{{t('global.currency')}}{{data.price}}</span>
            <span class="original-price">{{t('global.currency')}}{{data.originalPrice}}</span>
        </div>
    </div>
</template>

<script lang='ts'>
import { defineComponent } from 'vue';
import CartBtn from '@/components/common/CartBtn.vue';
import PictureBox from '@/components/common/PictureBox.vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'GoodsCard',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  components: {
    CartBtn,
    PictureBox,
  },
  setup() {
    const router = useRouter();
    const { t } = useI18n();

    return {
      router,
      t,
    };
  },
});
</script>

<style lang='scss'>
@import '@/assets/styles/variables.scss';
.r-goods-card {
    padding: 12px;
    background-color: #ffffff;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.11);
    border-radius: 6px;
    position: relative;
    &:hover {
        cursor: pointer;
        .ant-btn-primary {
            display: block;
        }
    }
    .ant-btn-primary {
        display: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        // width: 100%;
        border-radius: 5px;
        background-color: $theme-color;
        border-color: $theme-color;
        .cart-btn-icon {
            width: 21px;
            height: 19px;
            margin-right: 10px;
            display: inline-block;
        }
    }
    .goods-img {
        width: 100%;
        height: 61.8%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            // width: 100%;
            // height: 100%;
            object-fit: contain;
        }
    }
    .text {
        p {
            margin: 0;
        }
        .goods-name {
            font-size: 16px;
            color: $font-color;
            padding: 24px 0 0;
            height: 80px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
        }
        .original-price {
            font-size: 14px;
            color: #969696;
            text-decoration: line-through;
            line-height: 33px;
            padding-left: 20px;
        }
        .current-price {
            font-size: $font-size;
            color: $theme-color;
            font-weight: bold;
        }
    }
}
</style>
