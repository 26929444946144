
import { defineComponent } from 'vue';
import CartBtn from '@/components/common/CartBtn.vue';
import PictureBox from '@/components/common/PictureBox.vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'GoodsCard',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  components: {
    CartBtn,
    PictureBox,
  },
  setup() {
    const router = useRouter();
    const { t } = useI18n();

    return {
      router,
      t,
    };
  },
});
