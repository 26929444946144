<template>
    <div class="r-goods-list-for-category">
        <div class="r-container-l">
            <a-breadcrumb separator=">" class="r-breadcrumb" v-if="mode.isCategory">
                <a-breadcrumb-item>
                    <router-link to="/">{{t('goodsListForCategory.home')}}</router-link>
                </a-breadcrumb-item>
                <a-breadcrumb-item>
                    <router-link :to="`/category/${goodsParams.category.text}`">{{ goodsParams.category.name }}</router-link>
                </a-breadcrumb-item>
                <a-breadcrumb-item v-if="goodsParams.subcategory">
                    {{ goodsParams.subcategory.name }}
                </a-breadcrumb-item>
            </a-breadcrumb>
            <div class="list">
                <h2 v-if="mode.isSearch" class="search-keyword">{{t('goodsListForCategory.search')}}: {{ keyword }}</h2>
                <div class="left-menu" v-else>
                    <div class="title">{{ goodsParams.category.name }}</div>
                    <div class="category-body">
                        <p v-for="subcategory in goodsParams.category.subcategories" :key="subcategory.id">
                            <span v-if="subcategory.name === goodsParams.subcategory?.name" class="active">
                                {{ subcategory.name }}
                            </span>
                            <router-link
                                v-else
                                :to="`/category/${goodsParams.category.text}/goods/${subcategory.text}`"
                            >{{ subcategory.name }}</router-link>
                        </p>
                    </div>
                </div>
                <div class="right">
                    <div class="filter" v-if="mode.isCategory">
                        <GoodsFilter :specs="specs" @change="onGoodsFilterChange" />
                    </div>
                    <Load :loading="loading" v-if="loading" />
                    <div v-else-if="!loading && goods?.length !== 0">
                        <div class="goods-list" :style="{ marginTop: mode.isSearch ? '58px': null }">
                            <span class="goods-list-item" v-for="item in goods" :key="item.skuId">
                                <GoodsCard class="goods-card" :data="item"/>
                            </span>
                        </div>
                        <div class="pagination">
                            <a-pagination
                                simple
                                :page-size="pageSize"
                                v-model:current="current"
                                :total="total"
                                @change="pageChange"
                            />
                        </div>
                    </div>
                    <search-no-result v-else/>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import GoodsFilter, {
  Conditions,
} from '@/components/goodslist/GoodsFilter.vue';
import type { GoodsFilterProps } from '@/components/goodslist/GoodsFilter.vue';
import importComponents from '@/utils/import-components';
import { DownOutlined } from '@ant-design/icons-vue';
import {
  Button, Dropdown, Menu, Pagination, Breadcrumb,
} from 'ant-design-vue';
import {
  computed,
  defineComponent,
  ref,
  watch,
} from 'vue';
import GoodsCard from '@/components/GoodsCard.vue';
import { useRoute } from 'vue-router';
import { getFilter, getGoodsList, search } from '@/services/list';
import type { Goods } from '@/services/interface/response';
import Load from '@/components/common/Load.vue';
import SearchNoResult from '@/components/goodslist/SearchNoResult.vue';
import { useStore } from '@/store';

export default defineComponent({
  name: 'GoodsListForCategory',
  components: {
    GoodsFilter,
    GoodsCard,
    Load,
    SearchNoResult,
    ...importComponents(
      Button,
      Menu,
      Menu.Item,
      DownOutlined,
      Dropdown,
      Pagination,
      Breadcrumb,
      Breadcrumb.Item,
    ),
  },
  setup() {
    const { t } = useI18n();
    /** 列表页有三个走向：
         *  1.无筛选和有筛选
         *  2.搜索页
         */
    const loading = ref(true);
    const store = useStore();

    const route = useRoute();

    const mode = computed(() => {
      const isc = route.path.includes('/category');
      return {
        isCategory: isc,
        isSearch: !isc,
      };
    });
    // route.hash -> 解决搜索特殊字符如：#
    const keyword = computed(() => (route.hash === '#' ? '#' : route.query.keyword));

    const goods = ref<Goods[]>([]);

    const filterData = ref<Conditions>();

    const pageSize = ref(80);
    const current = ref(1);
    const total = ref(0);

    /**
         * @description 页面参数
         */
    const goodsParams = computed(() => {
      const { categoryName, goodsName } = route.params;
      const category = store.state.categories.find(({ text }) => text === categoryName);
      const subcategory = category?.subcategories?.find(({ text }) => text === goodsName);
      return {
        category: category ?? { id: '', name: '', subcategories: [] },
        subcategory,
        current: subcategory?.id ?? category?.id ?? '',
      };
    });

    const specs = ref<GoodsFilterProps['sepcs']>({});

    /**
         * @description 获取当前商品的规格
         */
    const fetchFilter = async () => {
      const res = await getFilter(goodsParams.value.category.id);
      const { success, data } = res;
      if (success) {
        // 设置规格
        specs.value = Object.entries(data).reduce((result, [key, value]) => {
          Reflect.set(result, key, value);
          return result;
        }, {} as GoodsFilterProps['sepcs']);
      }
    };

    const fetchGoodsList = async (param?: Conditions) => {
      if (!loading.value) {
        loading.value = true;
      }
      if (current.value === 0) {
        goods.value = [];
      }
      const params = {
        ...param,
        categoryId: goodsParams.value.current,
        current: current.value,
        pageSize: pageSize.value,
      };
      const res = await getGoodsList(params);
      const { data, success } = res;
      if (success) {
        if (data.list) {
          goods.value = data.list;
          total.value = data.total;
        } else {
          goods.value = [];
        }
        // 请求成功loading消失
        loading.value = false;
      }
    };

    const onGoodsFilterChange: GoodsFilterProps['change'] = (value) => {
      current.value = 1;
      fetchGoodsList(value);
      filterData.value = value;
    };

    const searchGoods = async () => {
      const data = {
        keyword: keyword.value as string,
        from: 0,
        pageSize: pageSize.value,
      };
      const res = await search(data);
      if (res.success && res.data.total !== 0) {
        goods.value = res.data.list;
        total.value = res.data.total;
      } else if (res.data.total === 0) {
        goods.value = [];
      }
      loading.value = false;
    };

    const pageChange = async (page: number) => {
      window.scrollTo(0, 0);
      loading.value = true;
      goods.value = [];
      if (mode.value.isCategory && !filterData.value) {
        current.value = page;
        fetchGoodsList();
      } else if (mode.value.isCategory && filterData.value) {
        current.value = page;
        fetchGoodsList(filterData.value);
      }
    };

    watch([goodsParams, keyword], () => {
      if (goodsParams.value.current) {
        fetchFilter();
        fetchGoodsList();
      } else if (keyword.value) {
        searchGoods();
      }
    }, { immediate: true, deep: true });

    return {
      route,
      mode,
      keyword,
      goodsParams,
      specs,
      goods,
      current,
      total,
      pageChange,
      pageSize,
      loading,
      onGoodsFilterChange,
      t,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixin.scss";
@import "@/assets/styles/variables.scss";

.r-goods-list-for-category {
    background-color: $background-color;
    .r-breadcrumb {
        font-size: 18px;
        line-height: 48px;
        color: #969696;
        font-weight: 400;
        a:hover {
            color: $theme-color;
        }
    }
    .list {
        display: flex;
        .search-keyword {
            font-size: 25px;
            font-weight: 500;
            color: #000000;
            line-height: 70px;
        }
        .right {
            flex: 1;
            .ant-btn:hover,
            .ant-btn:focus {
                color: $theme-color;
                border-color: $theme-color;
            }
            :deep(.ant-select:hover) {
                .ant-select-selector {
                    border-color: $theme-color;
                    box-shadow: $box-shadow;
                }
            }
        .pagination {
            text-align: center;
            padding: 51px 0 93px;
            :deep(.ant-pagination.ant-pagination-simple) {
            .ant-pagination-item-link {
                background-color: unset !important;
                .anticon {
                color: $font-color-weak;
                }
            }
            .ant-pagination-simple-pager {
                font-weight: bold;
                input {
                font-weight: bold;
                &:hover {
                    border-color: $theme-color;
                }
                }
                .ant-pagination-slash {
                font-weight: bold;
                }
            }
            }
        }
        }
    }

    .left-menu {
        width: 219px;
        border-radius: 4px;
        margin-right: 26px;
        .title {
            font-size: 20px;
            font-weight: $font-weight;
            color: $font-color;
            background-color: #ebebeb;
            padding: 16px 24px;
        }
        .category-body {
            font-size: $font-size;
            color: $font-color;
            padding: 24px;
            background: #fff;
            p {
                a {
                    color: $font-color;
                    &:hover {
                        color: $theme-color;
                    }
                    &.router-link-active.router-link-exact-active {
                        color: $theme-color;
                    }
                }
                .active {
                    color: $theme-color;
                }
            }
        }
    }
    .goods-list {
        margin-top: 20px;
        margin-right: -8px;
        margin-left: -8px;
        position: relative;
        height: auto;
        zoom: 1;
        display: flex;
        flex-flow: row wrap;
        box-sizing: border-box;
        .goods-list-item {
            padding-left: 8px;
            padding-right: 8px;
            display: block;
            box-sizing: border-box;
            max-width: 20%;
            width: 20%;
            flex: 0 0 20%;
            padding-bottom: 25%;
            height: 0;
            position: relative;
            margin-bottom: 16px;
        }
        .goods-card {
            position: absolute;
            width: calc(100% - 16px);
            height: 100%;
        }
    }
}
:deep(.loading) {
  border-bottom: none;
  background-color: unset;
}
</style>
