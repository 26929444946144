
import { useI18n } from 'vue-i18n';
import importComponents from '@/utils/import-components';
import { DownOutlined, CloseOutlined } from '@ant-design/icons-vue';
import {
  Button,
  Checkbox,
  Dropdown,
  Menu,
  InputNumber,
  Select,
} from 'ant-design-vue';
import {
  defineComponent, reactive, computed, ref,
} from 'vue';
import type { MenuOnClick } from '@/typings/ant-design-vue';
import { cloneDeep } from 'lodash';
import { useRoute } from 'vue-router';

type Specs = Record<string, string[]>;

export type Conditions = {
  sort: 0 | 1 | 2 | 3;
  attr: Specs;
  price: [number, number];
}

export interface GoodsFilterProps {
  change: (value: Conditions) => void;
  sepcs: Specs;
}

type Visibles = {
  specs: boolean[];
  price: boolean;
}

export default defineComponent({
  name: 'GoodsFilter',
  components: {
    ...importComponents(
      Button,
      Checkbox,
      Checkbox.Group,
      Menu,
      Menu.Item,
      DownOutlined,
      CloseOutlined,
      Dropdown,
      InputNumber,
      Select,
    ),
    Option: Select.Option,
  },
  props: {
    specs: {
      type: Object as () => Specs,
      required: true,
    },
  },
  setup(props, ctx) {
    const { t } = useI18n();
    /**
         * 可选择的规格 Key
         */
    const specKeys = computed(() => Object.keys(props.specs));

    /**
         * 选择的规格
         */
    const route = useRoute();
    const { sort } = route.query; // 首页图片点击url会带上sort指定选择的规格
    const conditions = reactive<Conditions>({
      sort: (sort ? +sort : 0) as 0 | 1 | 2 | 3,
      attr: {},
      price: [0, 0],
    });

    const disabled = ref(true);

    const emit = () => {
      const payload = cloneDeep(conditions);
      Object.entries(payload.attr).forEach(([key, value], index, array) => {
        if (value.length === 0) {
          Reflect.deleteProperty(payload.attr, key);
        }
        if (index === array.length - 1 && Object.keys(payload.attr).length === 0) {
          Reflect.set(payload, 'attr', {});
        }
      });
      ctx.emit('change', payload);
    };

    /**
         * 下拉框的显示隐藏
         */
    const visibles = reactive<Visibles>({
      specs: [],
      price: false,
    });

    const priceClick: MenuOnClick = ({ key }) => {
      if (key === 'btn') {
        visibles.price = false;
      }
    };

    /**
         * 清除规格
         */
    const onClear = (key: string) => {
      if (key === undefined) {
        conditions.attr = specKeys.value.reduce((result, specKey) => {
          Reflect.set(result, specKey, []);
          return result;
        }, {} as Specs);
        conditions.price = [0, 0];
        disabled.value = true;
      } else {
        conditions.attr[key] = [];
      }
      emit();
    };

    /**
         * 点击 APPLY 按钮后触发
         */
    const onApply = (type: 'specs' | 'price', index: number) => {
      if (type === 'specs') {
        visibles[type][index] = false;
      } else {
        visibles[type] = false;
      }
      disabled.value = false;
      emit();
    };

    /**
         * 排序规则被修改
         */
    const onSortRuleChange = (value: Conditions['sort']) => {
      conditions.sort = value;
      emit();
    };

    /**
         * 用在 template 中，判断规则是否有选择
         */
    const isSelected = (key: string) => {
      const attr = conditions.attr[key];
      if (attr === undefined || attr.length === 0) {
        return false;
      }
      return true;
    };

    /**
         * 下拉按钮上的 aria-expanded="false" 和 "true" 切换
         */
    // const ariaAttr = ref(false);
    // const changeAriaAttr = () => {
    //     ariaAttr.value = !ariaAttr.value;
    // };

    const formatter = (value: number) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    const parser = (value: string) => value.replace(/\$\s?|(,*)/g, '');

    return {
      isSelected,
      onClear,
      onApply,
      formatter,
      parser,
      visibles,
      priceClick,
      onSortRuleChange,
      conditions,
      specKeys,
      disabled,
      t,
      // ariaAttr,
      // changeAriaAttr,
    };
  },
});
