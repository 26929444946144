/*
 * @Author: your name
 * @Date: 2021-01-11 09:42:30
 * @LastEditTime: 2021-01-30 16:43:31
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /web-pc/src/services/list.ts
 */
import http from '@/utils/http';
import type * as Request from './interface/request';
import type * as Response from './interface/response';

const modulePath = '/list';

/**
 * @description: 列表页筛选选项
 * @param {string} categoryId
 * @return {*}
 */
export function getFilter(categoryId: string) {
  return http.client<Response.SkuFilter>(`${modulePath}/get_attr?categoryId=${categoryId}`);
}

/**
 * @description: 列表页
 * @param {string} categoryName
 * @return {*}
 */
export function getGoodsList(data: Request.GoodsList) {
  return http.client<Response.GoodsList>(`${modulePath}/get_goods`, {
    method: 'POST',
    data,
  });
}

/**
 * @description: 搜索及搜索分页
 * @param {Request} params
 * @return {*}
 */
export function search(params: Request.Search) {
  return http.client<Response.Search>('/search/get_goods', {
    params,
  });
}

export default {};
