
import { useI18n } from 'vue-i18n';
import GoodsFilter, {
  Conditions,
} from '@/components/goodslist/GoodsFilter.vue';
import type { GoodsFilterProps } from '@/components/goodslist/GoodsFilter.vue';
import importComponents from '@/utils/import-components';
import { DownOutlined } from '@ant-design/icons-vue';
import {
  Button, Dropdown, Menu, Pagination, Breadcrumb,
} from 'ant-design-vue';
import {
  computed,
  defineComponent,
  ref,
  watch,
} from 'vue';
import GoodsCard from '@/components/GoodsCard.vue';
import { useRoute } from 'vue-router';
import { getFilter, getGoodsList, search } from '@/services/list';
import type { Goods } from '@/services/interface/response';
import Load from '@/components/common/Load.vue';
import SearchNoResult from '@/components/goodslist/SearchNoResult.vue';
import { useStore } from '@/store';

export default defineComponent({
  name: 'GoodsListForCategory',
  components: {
    GoodsFilter,
    GoodsCard,
    Load,
    SearchNoResult,
    ...importComponents(
      Button,
      Menu,
      Menu.Item,
      DownOutlined,
      Dropdown,
      Pagination,
      Breadcrumb,
      Breadcrumb.Item,
    ),
  },
  setup() {
    const { t } = useI18n();
    /** 列表页有三个走向：
         *  1.无筛选和有筛选
         *  2.搜索页
         */
    const loading = ref(true);
    const store = useStore();

    const route = useRoute();

    const mode = computed(() => {
      const isc = route.path.includes('/category');
      return {
        isCategory: isc,
        isSearch: !isc,
      };
    });
    // route.hash -> 解决搜索特殊字符如：#
    const keyword = computed(() => (route.hash === '#' ? '#' : route.query.keyword));

    const goods = ref<Goods[]>([]);

    const filterData = ref<Conditions>();

    const pageSize = ref(80);
    const current = ref(1);
    const total = ref(0);

    /**
         * @description 页面参数
         */
    const goodsParams = computed(() => {
      const { categoryName, goodsName } = route.params;
      const category = store.state.categories.find(({ text }) => text === categoryName);
      const subcategory = category?.subcategories?.find(({ text }) => text === goodsName);
      return {
        category: category ?? { id: '', name: '', subcategories: [] },
        subcategory,
        current: subcategory?.id ?? category?.id ?? '',
      };
    });

    const specs = ref<GoodsFilterProps['sepcs']>({});

    /**
         * @description 获取当前商品的规格
         */
    const fetchFilter = async () => {
      const res = await getFilter(goodsParams.value.category.id);
      const { success, data } = res;
      if (success) {
        // 设置规格
        specs.value = Object.entries(data).reduce((result, [key, value]) => {
          Reflect.set(result, key, value);
          return result;
        }, {} as GoodsFilterProps['sepcs']);
      }
    };

    const fetchGoodsList = async (param?: Conditions) => {
      if (!loading.value) {
        loading.value = true;
      }
      if (current.value === 0) {
        goods.value = [];
      }
      const params = {
        ...param,
        categoryId: goodsParams.value.current,
        current: current.value,
        pageSize: pageSize.value,
      };
      const res = await getGoodsList(params);
      const { data, success } = res;
      if (success) {
        if (data.list) {
          goods.value = data.list;
          total.value = data.total;
        } else {
          goods.value = [];
        }
        // 请求成功loading消失
        loading.value = false;
      }
    };

    const onGoodsFilterChange: GoodsFilterProps['change'] = (value) => {
      current.value = 1;
      fetchGoodsList(value);
      filterData.value = value;
    };

    const searchGoods = async () => {
      const data = {
        keyword: keyword.value as string,
        from: 0,
        pageSize: pageSize.value,
      };
      const res = await search(data);
      if (res.success && res.data.total !== 0) {
        goods.value = res.data.list;
        total.value = res.data.total;
      } else if (res.data.total === 0) {
        goods.value = [];
      }
      loading.value = false;
    };

    const pageChange = async (page: number) => {
      window.scrollTo(0, 0);
      loading.value = true;
      goods.value = [];
      if (mode.value.isCategory && !filterData.value) {
        current.value = page;
        fetchGoodsList();
      } else if (mode.value.isCategory && filterData.value) {
        current.value = page;
        fetchGoodsList(filterData.value);
      }
    };

    watch([goodsParams, keyword], () => {
      if (goodsParams.value.current) {
        fetchFilter();
        fetchGoodsList();
      } else if (keyword.value) {
        searchGoods();
      }
    }, { immediate: true, deep: true });

    return {
      route,
      mode,
      keyword,
      goodsParams,
      specs,
      goods,
      current,
      total,
      pageChange,
      pageSize,
      loading,
      onGoodsFilterChange,
      t,
    };
  },
});
